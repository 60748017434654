import styled from 'styled-components';
import React, {
    SyntheticEvent,
    useCallback,
    useLayoutEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import {ImageWrapper} from 'shared/components/ImageLoader';

export const ProductBackgroundImage = ({
    image,
    src,
    alt = '',
    transform = 0,
    style,
    onLoad,
    fallbackSrc,
    resize = false,
    isHardware = false,
    cachingInProgress = false,
}: {
    src: string;
    transform?: number;
    onLoad?: () => void;
    fallbackSrc?: string;
    alt?: string;
    image?: string;
    style?: React.CSSProperties;
    resize?: boolean;
    isHardware?: boolean;
    cachingInProgress?: boolean;
}) => {
    const [show, setShow] = useState(false);
    const [imageStyle, setImageStyle] = useState<React.CSSProperties>({
        width: '100%',
        height: '100%',
    });
    const containerRef = useRef<HTMLDivElement>();
    const imageRef = useRef<HTMLImageElement>();
    const fallbackLoad = useRef(false);

    const resizeImage = useCallback((image: HTMLImageElement) => {
        if (containerRef.current) {
            const containerDimensions =
                containerRef.current.getBoundingClientRect();

            if (image) {
                const width = image.naturalWidth;
                const height = image.naturalHeight;
                const maxHeight = containerDimensions.height;
                const maxWidth = containerDimensions.width;

                if (width > height) {
                    setImageStyle((style) => ({
                        ...style,
                        maxWidth: `${maxWidth}px`,
                    }));
                } else if (width < height) {
                    setImageStyle((style) => ({
                        ...style,
                        maxHeight: `${maxHeight}px`,
                    }));
                } else {
                    setImageStyle((style) => ({
                        ...style,
                        maxWidth: `${maxWidth}px`,
                        maxHeight: `${maxHeight}px`,
                    }));
                }

                setShow(true);
            }
        }
    }, []);

    const imageLoadHandler = useCallback(
        (event: SyntheticEvent<HTMLImageElement>) => {
            const image = event.currentTarget;
            resizeImage(image);
            if (typeof onLoad === 'function') {
                onLoad();
            }
        },
        [onLoad]
    );

    const imageLoadErrorHandler = useCallback(() => {
        if (imageRef.current && !fallbackLoad.current && fallbackSrc) {
            fallbackLoad.current = true;
            imageRef.current.src = fallbackSrc.startsWith('/')
                ? fallbackSrc
                : `/${fallbackSrc}`;
        } else {
            setShow(false);
        }
    }, [fallbackSrc]);

    const imageSource = useMemo(() => {
        if (src && src != '' && src != null && src != 'null') {
            return src.startsWith('/') ? src : `/${src}`;
        }

        if (fallbackSrc) {
            return fallbackSrc.startsWith('/')
                ? fallbackSrc
                : `/${fallbackSrc}`;
        }

        return '';
    }, [src, fallbackSrc]);

    const resizeHandler = useCallback(() => {
        if (show) {
            resizeImage(imageRef.current);
        }
    }, [show]);

    useLayoutEffect(() => {
        if (resize) {
            const container = document.querySelector('.mainContent');

            if (container) {
                const observer = new ResizeObserver(resizeHandler);

                observer.observe(container);

                return () => observer.unobserve(container);
            }
        }
    }, [resize, resizeHandler]);

    return (
        <OuterContainer ref={containerRef}>
            <ImageContainer
                $isHardware={isHardware}
                $show={show}
                $image={image}
                $transform={transform == 1}>
                {cachingInProgress ? (
                    <ImageCacheContainer>
                        <ImageCachePlaceholder>
                            Preview being regenerated... refresh page soon
                        </ImageCachePlaceholder>
                    </ImageCacheContainer>
                ) : null}
                <ImageLoader style={style} $display={!show} />
                <Image
                    ref={imageRef}
                    $show={show}
                    style={{...style, ...imageStyle}}
                    src={imageSource}
                    alt={alt ? alt : ''}
                    onLoad={imageLoadHandler}
                    onError={imageLoadErrorHandler}
                />
            </ImageContainer>
        </OuterContainer>
    );
};

const OuterContainer = styled.div`
    display: flex;
    width: inherit;
    height: inherit;
    justify-content: space-around;
    align-items: center;
    background: white;
    border-radius: 10px;
`;

const ImageContainer = styled.div<{
    $image: string;
    $transform: boolean;
    $show?: boolean;
    $isHardware?: boolean;
}>`
    position: relative;
    overflow: hidden;
    width: ${({$show, $isHardware}) =>
        !$show || $isHardware ? 'inherit' : 'auto'};
    height: ${({$show, $isHardware}) =>
        !$show || $isHardware ? 'inherit' : 'auto'};

    &:before {
        display: ${({$show = false}) => ($show ? 'block' : 'none')};
        content: '';
        position: absolute;
        inset: 1px;
        border-radius: 20px;
        background: ${({$image}) => $image};
        transform: ${({$transform}) => ($transform ? 'rotate(90deg)' : '')};
    }
`;

const Image = styled.img<{$show: boolean}>`
    display: ${({$show = false}) => ($show ? 'block' : 'none')};
    position: relative;
    z-index: 2;
    width: 0;
    height: 0;
    object-fit: contain;
`;

const ImageLoader = styled(ImageWrapper)<{
    $display: boolean;
}>`
    border-radius: 10px;
    width: inherit;
    height: inherit;
    display: ${({$display}) => ($display ? 'block' : 'none')};
`;

const ImageCacheContainer = styled.div`
    display: flex;
    align-items: center;
    position: absolute;
    z-index: 5;
    padding: 10px;
    background: rgba(255, 255, 255, 0.7);
    height: 100%;
    border-radius: 8px;
`;

const ImageCachePlaceholder = styled.div`
    text-align: center;
    font-weight: 600;
    font-size: 13px;
    color: #616161;
`;
